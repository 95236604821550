
/**
 * 
 * @returns 
* Title Banner for Website used for announcements and welcoming visitors
*/
import { Authenticator } from '@aws-amplify/ui-react';
import React from 'react';
import signuplogo from '../images/signuplogo.png';


export default function aws_login_component()
{
    return(
        <div className='whiteFont padding'>               
            <h2 className='whiteFont emphTitle2 text-center'>Portal Updates</h2>
            <hr></hr>
            <br></br>
           <br></br>

            <Authenticator className='login_container container' loginMechanisms={['email']} hideSignUp> 
                {({ signOut, user}) => (
                    <div className='whiteFont'>
                        <div className='LoggedInUser'>        
                            <h3 className='emphTitle'>Welcome, <p className='whiteFont'> {user.attributes.email} </p></h3>
                            <br></br>
                            <hr></hr>
                            <br></br>
                        </div>
                        <div className='UserTips'>

                            <h4 className='emphTitle'>MHB News / Updates (Updated 11/1/2024)</h4>
                            <ul>
                                <li>
                                    <p> Portal Updated with Payments up to 11/1/24 in S8, HAPPY, and MRVP Tables.<br></br></p> 
                                </li>
                                <li className='hide'>
                                    <p>Newly Added Feature: Export functionality on Payments page. <a className='btn btn-primary' href='/payments'>Export Payments</a></p>
                                </li>
                                <li className='hide'>
                                    <p>Newly Added Feature: Submit Feedback on the Footer menu below. <a className='btn btn-primary' href='https://forms.office.com/Pages/ResponsePage.aspx?id=J8xXIgxdK0y6AnOQOwjqAHVPPCEmmcRLu9IABP8zZRBUQlFDU0RFQUxRRjdCTzY4NTZQWjVMS09SVC4u'>Submit Feedback</a></p>
                                </li>
                            </ul>
                            

                            <h4 className='emphTitle'>Previous Announcements</h4>
                            <ul>
                                <li>
                                    <p>We are addressing missing, duplicate, or invalid payments requests as they arrive. Please submit a ticket if you encounter any of these issues. <b></b></p> 
                                </li>
                            </ul>
                         
                            <h4 className='emphTitle'>Additional Announcements</h4>
                            <ul>
                                <li>
                                    <p>Coming Soon: Rent Share Updates Database will be added for 2024 Updates <b></b></p> 
                                </li>
                                <li>
                                    <p>Coming Soon: Sort Functionality for Payments Page <b></b></p> 
                                </li>
                                <li>
                                    <p>Coming Soon: Updated FAQ Page with new resources to contact at MHB<b></b></p> 
                                </li>
                            </ul>
                            
                        </div>
                    </div>
                )}
            </Authenticator>
            <img src={signuplogo} className='signuplogo padding' alt="metroBG.jpg" />
            
            <br></br>
            <br></br>
            
            <div className='text-center portalUpdateInfo'>
                <h3 className='whiteFont emphTitle2 text-center'>Portal Updates</h3>
                <br></br>
                <h5 className='whiteFont'>SECTION 8 PAYMENTS: 12/1/24</h5>
                <h5 className='whiteFont'>MRVP PAYMENTS: 12/1/24</h5>
                <h5 className='whiteFont'>RAFT/HOMEBASE PAYMENTS: 12/1/24</h5>
                <br></br>
            </div>
            
        </div>

    );
}